import React from "react";
// Styles
import Styled from "./Icon.styled";
// Tools
import PropTypes from "prop-types";

// Dynamically import SVG icons
const icons = {
  dashboard: require("../../../assets/icons/dashboard.svg").ReactComponent,
  venue: require("../../../assets/icons/venue.svg").ReactComponent,
  settings: require("../../../assets/icons/settings.svg").ReactComponent,
  chat: require("../../../assets/icons/talk_to_us.svg").ReactComponent,
  logout: require("../../../assets/icons/logout.svg").ReactComponent,
  // Add more icons here
  venues: require("../../../assets/icons/venues.svg").ReactComponent,
  inferences: require("../../../assets/icons/inferences.svg").ReactComponent,
  cameras: require("../../../assets/icons/cameras.svg").ReactComponent,
  users: require("../../../assets/icons/users.svg").ReactComponent,
  licenses: require("../../../assets/icons/licenses.svg").ReactComponent,
  search: require("../../../assets/icons/search.svg").ReactComponent,
  arrow_filter: require("../../../assets/icons/arrow-down.svg").ReactComponent,
  plus: require("../../../assets/icons/plus.svg").ReactComponent,
  pause: require("../../../assets/icons/pause.svg").ReactComponent,
  play: require("../../../assets/icons/play.svg").ReactComponent,
  more_vertical: require("../../../assets/icons/more_vertical.svg").ReactComponent,
  check: require("../../../assets/icons/check.svg").ReactComponent,
  command: require("../../../assets/icons/command.svg").ReactComponent,
  user_square: require("../../../assets/icons/user-square.svg").ReactComponent,
  house_add: require("../../../assets/icons/house_add.svg").ReactComponent,
  arrow_up: require("../../../assets/icons/arrow_up_gray.svg").ReactComponent,
  arrow_down: require("../../../assets/icons/arrow_down_gray.svg").ReactComponent,
  dot_menu: require("../../../assets/icons/dot_menu.svg").ReactComponent,
  arrow_left: require("../../../assets/icons/arrow_left.svg").ReactComponent,
  chart_line: require("../../../assets/icons/chart_line.svg").ReactComponent,
  link: require("../../../assets/icons/link.svg").ReactComponent,
  download: require("../../../assets/icons/download.svg").ReactComponent,
  info: require("../../../assets/icons/info.svg").ReactComponent,
  eye: require("../../../assets/icons/eye.svg").ReactComponent,
  select_multiple: require("../../../assets/icons/select_multiple.svg").ReactComponent,
  refresh: require("../../../assets/icons/refresh.svg").ReactComponent,
  edit_pencil: require("../../../assets/icons/edit_pencil.svg").ReactComponent,
  trash_empty: require("../../../assets/icons/trash_empty.svg").ReactComponent,
  start_inference: require("../../../assets/icons/start_inference.svg").ReactComponent,
  company: require("../../../assets/icons/company.svg").ReactComponent,
  menu: require("../../../assets/icons/menu.svg").ReactComponent,
  text: require("../../../assets/icons/text.svg").ReactComponent,
  edit: require("../../../assets/icons/edit.svg").ReactComponent,
  delete: require("../../../assets/icons/delete.svg").ReactComponent,
  add_plus_circle: require("../../../assets/icons/add_plus_circle.svg").ReactComponent,
  arrows_reload: require("../../../assets/icons/arrows_reload.svg").ReactComponent,
  edit_camera: require("../../../assets/icons/edit_icon_updated.svg").ReactComponent,
  camera_form_icon: require("../../../assets/icons/camera_form_icon.svg").ReactComponent,
  behavior_analysis: require("../../../assets/icons/behavior_analysis.svg").ReactComponent,
  crowd_estimation: require("../../../assets/icons/crowd_estimation.svg").ReactComponent,
  dwell_time: require("../../../assets/icons/dwell_time.svg").ReactComponent,
  ingress_egress: require("../../../assets/icons/ingress_egress.svg").ReactComponent,
  lic_plate_recog: require("../../../assets/icons/lic_plate_recog.svg").ReactComponent,
  object_counting: require("../../../assets/icons/object_counting.svg").ReactComponent,
  queue_time: require("../../../assets/icons/queue_time.svg").ReactComponent,
  other: require("../../../assets/icons/other_model.svg").ReactComponent,
  add_area: require("../../../assets/icons/add_area.svg").ReactComponent,
  calendar: require("../../../assets/icons/calendar.svg").ReactComponent,
};

const Icon = ({ name, size = "24px", ...props }) => {
  const SvgIcon = icons[name];

  if (!SvgIcon) {
    return;
  }

  return (
    <Styled.IconWrapper size={size} {...props}>
      <SvgIcon />
    </Styled.IconWrapper>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default Icon;
